import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Layout from "app/components/Layout";
import { privateRoutes, publicRoutes } from "./utils/routes";
import { useAuthenticationStateContext } from "./contexts/AuthenticationStateContext";
import NotFoundPage from "app/pages/common/NotFoundPage";

const Router: React.FunctionComponent = () => {
  const { initialized } = useKeycloak();
  const { authenticationState } = useAuthenticationStateContext();

  if (initialized) {
    return (
      <BrowserRouter>
        {authenticationState?.isAuthenticated ? (
          <Route
            render={(props) => (
              <Layout {...props}>
                <Switch>
                  {privateRoutes.map((route) => (
                    <Route
                      exact
                      path={route.path}
                      key={route.key}
                      component={(props: any) => <route.Component {...props} />}
                    />
                  ))}
                  <Route
                    component={(props: any) => <NotFoundPage {...props} />}
                  />
                </Switch>
              </Layout>
            )}
          />
        ) : (
          <Route
            render={(props) => (
              <Layout hideHeader {...props}>
                <Switch>
                  {publicRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      key={route.key}
                      component={route.Component}
                    />
                  ))}
                </Switch>
              </Layout>
            )}
          />
        )}
      </BrowserRouter>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <CircularProgress />
      <Typography sx={{ marginTop: 2 }} variant="body1">
        Chargement de l'application
      </Typography>
    </Box>
  );
};

export default Router;
