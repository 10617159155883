import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  bar1Determinate: {
    background:
      "linear-gradient(to left,var(--secondary-color), var(--primary-color))",
  },
  bar2Determinate: {
    background: "#333333",
  },
});

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number },
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          classes={{
            bar1Determinate:
              props && props.classes && props.classes.bar1Determinate,
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(LinearProgressWithLabel);
