import React from "react";
import Providers from "app/Providers";
import "./App.css";
import Router from "app/Router";

const App: React.FunctionComponent = () => {
  return (
    <Providers>
      <Router />
    </Providers>
  );
};

export default App;
