import Box from "@mui/system/Box";
import React from "react";
import { Grid, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

const DepotRetour = ({ errorMessage, setErrorMessage, setClickUpload }) => {
  function handleReturnUpload() {
    setErrorMessage(null);
    setClickUpload(false);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "60%",
          borderRadius: 2,
          border: `1px dashed var(--primary-color)`,
          bgcolor: "cobalt.white",
          mb: 3,
          p: 4,
        }}
      >
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={4} textAlign="center">
            {!!errorMessage ? (
              <img
                src={process.env.PUBLIC_URL + "/images/icon_error_upload.png"}
                alt="import en erreur"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/images/icon_success_upload.png"}
                alt="import terminé"
              />
            )}
          </Grid>
          <Grid item md={4} textAlign="center">
            <Typography variant="body1">
              {!!errorMessage
                ? "Le transfert a échoué."
                : "Le transfert est terminé."}
            </Typography>
          </Grid>
          {!!errorMessage && (
            <Grid item md={4} textAlign="center">
              <Typography variant="body1" color="red">
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item md={4} textAlign="center" mt={5}>
            <Link
              variant="body1"
              sx={{
                cursor: "pointer",
                color: "#5eb8ac",
                "&.MuiLink-root:hover": {
                  color: "#394285FF",
                },
              }}
              onClick={() => {
                handleReturnUpload();
              }}
            >
              {!!errorMessage ? "Recommencer" : "Retour à la page d'accueil"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DepotRetour;
