import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ContentLayout from "app/components/ContentLayout";

function NotFoundPage(): JSX.Element {
  return (
    <ContentLayout>
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1">Page non trouvée</Typography>
        <Typography variant="body1">
          Oups... La page que vous recherchez ne semble pas exister
        </Typography>
      </Box>
    </ContentLayout>
  );
}

export default NotFoundPage;
