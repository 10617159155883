import IRoute from "app/interfaces/route";
import Depot from "app/pages/private/depot/Depot";
import Login from "app/pages/public/Login";

export const publicRoutes: IRoute[] = [
  {
    key: "LOGIN",
    path: "/",
    exact: true,
    Component: Login,
    label: "LOGIN",
  },
];

export const privateRoutes: IRoute[] = [
  {
    key: "DEPOT",
    path: "/depot",
    exact: true,
    Component: Depot,
    label: "Depot",
  },
];

export const getRouteByKey: (key: string) => IRoute | null = (key) => {
  return privateRoutes.find((route) => route.key === key) || null;
};
