import React, { createContext, useContext, useMemo, useState } from "react";

export const defaultAuthenticationState = {
  isAuthenticated: false,
  hasAccess: false,
};

export type AuthenticationStateProps = {
  authenticationState: {
    isAuthenticated: boolean;
    hasAccess: boolean;
  };
  setAuthenticationState: React.Dispatch<
    React.SetStateAction<{
      isAuthenticated: boolean;
      hasAccess: boolean;
    }>
  >;
};

const AuthenticationStateContext = createContext<AuthenticationStateProps>({
  authenticationState: defaultAuthenticationState,
  setAuthenticationState: () => {},
});

declare type AuthenticationStateContextProviderProps = {
  children: React.ReactNode;
};

export function AuthenticationStateContextProvider({
  children,
}: AuthenticationStateContextProviderProps): JSX.Element {
  const [authenticationState, setAuthenticationState] = useState(
    defaultAuthenticationState,
  );
  const value: AuthenticationStateProps = useMemo(
    () => ({ authenticationState, setAuthenticationState }),
    [authenticationState],
  );

  return (
    <AuthenticationStateContext.Provider value={value}>
      {children}
    </AuthenticationStateContext.Provider>
  );
}

export const useAuthenticationStateContext = () =>
  useContext(AuthenticationStateContext);

export default AuthenticationStateContext;
