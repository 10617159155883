export const APP_NAME = "EDS AGORIA SANTE";
/*page depot*/
export const MESSAGE_ERROR_TOO_LARGE =
  "La taille du fichier ne doit pas excéder " +
  window.__env__.UPLOAD_FILE_MAX_SIZE +
  ".";
export const MESSAGE_ERROR_WRONG_TYPE =
  "Le format du fichier sélectionné n’est pas accepté.";
export const MESSAGE_ERROR =
  "L'import du fichier a échoué à cause d'une erreur interne";
