import React from "react";
import Box from "@mui/material/Box";
import Header from "./Header";
import { colors } from "@docaposte-agility/da-design-system/theme/theme";

declare type LayoutProps = {
  children: React.ReactNode;
  hideHeader?: boolean;
};

function Layout({ children, hideHeader }: LayoutProps): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: colors.light,
        height: "inherit",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!hideHeader && <Header />}
      {children}
    </Box>
  );
}

export default Layout;
