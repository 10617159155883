import React from "react";
import "./BlockUI.css";
import Typography from "@mui/material/Typography";

const BlockUI = (props) => {
  if (!props.blocking) {
    return <div />;
  } else {
    return (
      <div className="block-ui-container">
        <div className="block-ui-overlay" />
        <div className="block-ui-message-container">
          <div className="block-ui-message">
            <Typography
              variant="h2"
              sx={{
                color: "black",
              }}
            >
              {props.message}
            </Typography>
            <div className="loading-indicator">
              <svg id="indicator" viewBox="0 0 100 100">
                <circle id="circle" cx="50" cy="50" r="45" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default BlockUI;
