import React, { useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CobaltIcon } from "@docaposte-agility/da-design-system/";
import { Identifier } from "app/interfaces/login";
import { APP_NAME } from "app/constants/constants";
import { useKeycloak } from "@react-keycloak/web";

const Login = () => {
  const { keycloak } = useKeycloak();

  const onSubmit = useCallback(() => {
    keycloak?.login({ redirectUri: window.location.href });
  }, [keycloak]);

  const { register, handleSubmit } = useForm<Identifier>();
  const [login, setLogin] = React.useState<Identifier>({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (
    prop: keyof Identifier,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLogin({ ...login, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        display: "flex",
        background: `url("/images/background-login.png") center center no-repeat fixed`,
        backgroundSize: "800px 332px",
        backgroundPositionX: "10%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          flex: "1 auto",
          display: "flex",
          justifyContent: "flex-end",
          mr: 20,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            position: "relative",
            width: "415px",
            flex: "0 auto",
            p: 5,
          }}
        >
          <Typography variant="h2" sx={{ color: "var(--primary-color)" }}>
            Accédez à
          </Typography>
          <Typography variant="h1" sx={{ color: "var(--secondary-color)" }}>
            {APP_NAME}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ mt: 2 }} flexDirection="column" spacing={2}>
              <Grid item>
                <TextField
                  id="idLoginField"
                  inputProps={{
                    "aria-label": "Identifiant",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Typography variant="subtitle2">Identifiant</Typography>
                    </Box>
                  }
                  placeholder="Identifiant"
                  fullWidth
                  value={login.username}
                  {...register("username", {
                    required: true,
                    onChange: (e) => {
                      handleChange("username", e);
                    },
                  })}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="idPasswordField"
                  aria-label="mot de passe"
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Typography variant="subtitle2">Mot de passe</Typography>
                    </Box>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CobaltIcon
                          id="idShowPasswordButton"
                          sx={{ cursor: "pointer" }}
                          name={showPassword ? "eye-off" : "eye"}
                          onClick={handleClickShowPassword}
                        />
                      </InputAdornment>
                    ),
                    "aria-label": "mot de passe",
                  }}
                  type={showPassword ? "text" : "password"}
                  placeholder="mot de passe"
                  fullWidth
                  value={login.password}
                  {...register("password", {
                    required: true,
                    onChange: (e) => {
                      handleChange("password", e);
                    },
                  })}
                />
              </Grid>
              <Grid item>
                <Link
                  href="/"
                  target="_blank"
                  sx={{
                    color: "var(--primary-color)",
                    "&.MuiLink-root:hover": {
                      color: "var(--secondary-color)",
                    },
                  }}
                >
                  Mot de passe oublié ?
                </Link>
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <Button
                  id="idSignupButton"
                  fullWidth
                  sx={{
                    backgroundColor: "var(--secondary-color)",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "var(--primary-color)",
                    },
                  }}
                  type="submit"
                >
                  Connexion
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};
export default Login;
