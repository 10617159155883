import React, { useCallback } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import keycloak from "../config/keycloak";
import { useAuthenticationStateContext } from "../contexts/AuthenticationStateContext";

declare type KeycloakWrapperProps = {
  children: React.ReactNode;
};

function KeycloakWrapper({ children }: KeycloakWrapperProps): JSX.Element {
  const { setAuthenticationState } = useAuthenticationStateContext();
  const authClient = keycloak;

  const eventLogger: (event: any, error: any) => void = useCallback(
    (event) => {
      if (event === "onAuthSuccess") {
        setAuthenticationState({
          isAuthenticated: true,
          hasAccess: true,
        });
      }
      if (event === "onAuthRefreshError") {
        keycloak?.logout({
          redirectUri: window.location.href,
        });
      }
    },
    [setAuthenticationState],
  );

  if (authClient) {
    return (
      <ReactKeycloakProvider
        authClient={authClient}
        onEvent={eventLogger}
        initOptions={{
          pkceMethod: "S256",
          redirectUri: window.__env__.KEYCLOAK_REDIRECT_URI,
          onLoad: "login-required",
          checkLoginIframe: false,
        }}
      >
        {children}
      </ReactKeycloakProvider>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Typography variant="body1">
        Impossible de charger l'application
      </Typography>
    </Box>
  );
}

export default KeycloakWrapper;
