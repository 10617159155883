import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
const keycloak = Keycloak({
  url: window.__env__.KEYCLOAK_BASE_URL,
  realm: window.__env__.KEYCLOAK_REALM || "",
  clientId: window.__env__.KEYCLOAK_CLIENT_ID || "",
});

export default keycloak;
