import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiDivider from "@mui/material/Divider";
import { APP_NAME } from "app/constants/constants";
import { useKeycloak } from "@react-keycloak/web";

const Divider: React.FC<{}> = () => (
  <MuiDivider
    sx={{
      marginRight: 4.5,
      marginTop: 0.75,
      marginBottom: 0.75,
      paddingRight: 1,
      paddingLeft: 1,
      height: "32px",
      borderColor: "cobalt.ultramarine",
    }}
    orientation="vertical"
  />
);

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { keycloak } = useKeycloak();

  const getInitials = (firstName: String, lastName: String) => {
    return firstName && lastName && firstName[0] + lastName[0];
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeconnect = () => {
    setAnchorEl(null);
    keycloak.logout();
  };

  return (
    <AppBar sx={{ position: "sticky" }}>
      <Toolbar sx={{ boxShadow: 2 }}>
        <img
          src={process.env.PUBLIC_URL + "/images/logo_AGORIA SANTE.png"}
          alt="logo agoria sante"
          style={{ marginLeft: 45 }}
        />

        <Typography
          sx={{
            color: "var(--secondary-color)",
            flex: "1 1 auto",
            display: "flex",
            justifyContent: "center",
          }}
          variant="h1"
        >
          {APP_NAME}
        </Typography>
        <Divider />
        <Button
          sx={{
            padding: 0.75,
            mx: 1,
            my: 0.5,
          }}
          variant="text"
          startIcon={
            <Avatar
              alt="0"
              sx={{
                bgcolor: "var(--secondary-color)",
                width: "32px",
                height: "32px",
              }}
            >
              <Typography variant="h5" color="cobalt.white">
                {getInitials(
                  keycloak?.tokenParsed?.given_name,
                  keycloak?.tokenParsed?.family_name,
                )}
              </Typography>
            </Avatar>
          }
          onClick={handleMenu}
        >
          <Typography variant="h5" color="var(--primary-color)">
            {keycloak?.tokenParsed?.name
              ? keycloak?.tokenParsed?.name
              : keycloak?.tokenParsed?.preferred_username}
          </Typography>
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          sx={{
            ml: 2,
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDeconnect}>Déconnection</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
