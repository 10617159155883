export const bytesToHuman = (bytes) => {
  if (bytes === 0) {
    return "0.00 B";
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B"
  );
};

export const humanToBytes = (text) => {
  const powers = { k: 1, m: 2, g: 3, t: 4 };
  const regex = /(\d+(?:\.\d+)?)\s?(k|m|g|t)?b?/i;

  const res = regex.exec(text);

  return res[1] * Math.pow(1024, powers[res[2].toLowerCase()]);
};
