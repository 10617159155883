import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@docaposte-agility/da-design-system";
import { AuthenticationStateContextProvider } from "app/contexts/AuthenticationStateContext";
import KeycloakWrapper from "app/keycloak/KeycloakWrapper";

const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AuthenticationStateContextProvider>
        <KeycloakWrapper>{children}</KeycloakWrapper>
      </AuthenticationStateContextProvider>
    </ThemeProvider>
  );
};

export default Providers;
